@function text-stroke($size: 2, $color: #fff, $correction: 0) {
  $radius: $size - $correction;
  $stroke: ();

  @for $i from -$size through $size {
    @for $k from -$size through $size {

      $x: $k;
      $y: $i;

      @if $k > 0 {
        $x: $k - 0.5;
      } @elseif $k < 0 {
        $x: $k + 0.5;
      }

      @if $i > 0 {
        $y: $i - 0.5;
      } @elseif $i < 0 {
        $y: $i + 0.5;
      }

      @if ($x*$x + $y*$y <= $radius*$radius) {
        $stroke: append($stroke, $i*1px $k*1px 0 $color, comma);
      }
    }
  }

  @return $stroke;
}

@mixin text-stroke($size: 2, $color: #fff, $correction: 0) {
  text-shadow: text-stroke($size, $color, $correction);
}

// Code below mostly from https://codepen.io/pixelass/pen/gbGZYL

html {
  background: #f5f5f5;
  font-weight: 900;
  font-size: (100%/16)*14;
  font-family: "Armata", sans-serif;
}
.Welcome-text {
  @include text-stroke(7, #754C29);

  color: #FFE8C7 ;
  font-size: 3em;
}

.Room-Selection-text {
  @include text-stroke(7, #754C29);

  color: #FFE8C7 ;
  font-size: 3em;
}

.Welcome-text-bolder {
  @include text-stroke(20, #754C29);

  color: #FFE8C7 ;
  font-size: 7em;
}

.helloText {
  @include text-stroke(7, #754C29);

  color: #FFE8C7 ;
  font-size: 1em;
}
.function {
  @include text-stroke(2, #ff6f00);

  color: #ffe082;
}
.mixin {
  @include text-stroke(3, #cddc39);

  color: #827717;
}
.large {
  font-size: 1em;
  line-height: 1.3;
  div {
    margin: 4px;
  }
}
.symbols {
  @include text-stroke(10, red);

  font-size: 80px;
}

@function _ss-str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + _ss-str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@import url("https://fonts.googleapis.com/css?family=Armata|Atomic+Age|Sigmar+One|Fontdiner+Swanky|Graduate|Luckiest+Guy|Freckle+Face|Iceland|UnifrakturMaguntia|Titan+One|Oregano|Miltonian+Tattoo|Raleway+Dots|Milonga|Pirata+One|Wellfleet|Medula+One|Lato|Roboto|Open Sans");

$font-families: ("Armata", "Medula One", "Atomic Age", "Sigmar One", "Fontdiner Swanky", "Graduate", "Luckiest Guy", "Freckle Face", "Iceland", "UnifrakturMaguntia", "Titan One", "Oregano", "Miltonian Tattoo", "Raleway Dots", "Milonga", "Pirata One", "Wellfleet", "Lato", "Roboto", "Open Sans", "Arial", "Georgia");

@for $i from 1 through length($font-families) {
  $font-family: nth($font-families, $i);
  $classname: _ss-str-replace($font-family, ' ', '-');
  .#{$classname} {
    $deg: (360/length($font-families)*0.9);
    @include text-stroke(max(2, $i%5), hsl($i*$deg, 100%, 50%));

    font-family: $font-family;
    color: hsl($i*$deg - 180, 100%, 50%);
  }
}
