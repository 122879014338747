@font-face {
  font-family: LeelaUIb;
  src: url("assets/fonts/LeelaUIb.ttf") format("truetype");
}

@font-face {
  font-family: SilentCreek;
  src: url("assets/fonts/SilentCreek.otf") format("opentype");
}

@font-face {
  font-family: SilentCreek-Distressed;
  src: url("assets/fonts/SilentCreek-Distressed.otf") format("opentype");
}

@font-face {
  font-family: DevinneSwash;
  src: url("assets/fonts/DevinneSwash.ttf") format("truetype");
}

@font-face {
  font-family: DevinneSwashShadow;
  src: url("assets/fonts/DevinneSwashShadow.ttf") format("truetype");
}

@font-face {
  font-family: MyriadPro;
  src: url("assets/fonts/Myriad\ Pro\ Regular.ttf") format("truetype");
}

.Container {
  background-image: url("../public/main-bg.jpeg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: DevinneSwash;
  color: #8d4d15;
}

.Container-Loading {
  background-image: url("../public/main-bg.jpeg");
  min-height: 100vh;
  max-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: DevinneSwash;
  color: #8d4d15;
}

.Welcome-text {
  font-family: DevinneSwash;
  font-style: normal;
  font-weight: 800;
  font-size: 3em;
  line-height: 1em;
  letter-spacing: 0.1em;

  color: #FFE8C7;

  /*-webkit-text-stroke: 2px #754C29;*/
}

.Room-Selection-text {
  font-family: DevinneSwash;
  font-style: normal;
  font-weight: 800;
  font-size: 5vh;
  line-height: 1em;
  letter-spacing: 0.1em;

  color: #FFE8C7;

  /*-webkit-text-stroke: 2px #754C29;*/
}

.Loading-text {
  width: 60vw;
  display: block;
  margin: 0 auto;
  min-width: 300px;
  object-fit: contain;
}

.Vertical-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.Login-bg {
  background-image: url("../public/login-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 60em;
  min-width: 30em;
}

@media only screen and (max-width: 700px) {
  .Login-bg {
    margin-left: 15px;
    margin-right: 15px;
  }

  .labelText {
    font-size: 2em;
  }

  .labelTextInnerText{
    font-size: 2em;
  }

  .Input-bg.characterName{
    padding-right: 0em;
  }
}

@media only screen and (max-width: 992px) {
  .Input-bg.characterName{
    padding-right: 0em !important;
  }
}

.Login-text {
  font-size: 2em;
  line-height: 1em;
  font-family: DevinneSwash;
}

.Login-description-text {
  font-size: 1.5em;
  font-family: MyriadPro;
  font-style: normal;
  font-weight: 400;
  color: #66472D;
  line-height: 24px;
}

.Login-btn {
  width: 80vw;
  max-width: 350px;
}

.Color-black {
  color: black
}

.Container-flex-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 3;
}

.Flex-1-centered {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Flex-0-9-centered {
  flex: .9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Cursor-pointer {
  cursor: pointer;
}

.Input {
  font-family: MyriadPro;
  background-color: #EFE3DA;
  border-radius: 0;
  width: fit-content;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 4px solid #754C29;
  border-radius: 7px;
}

.Input-bg {
  background-color: #E0E0E0;
  font-size: 22px;
}

.Mb-2 {
  margin-bottom: 2rem;
}

.Mb-5 {
  margin-bottom: 3rem;
}

.Icon {
  margin-left: .5rem;
  height: 38px;
  object-fit: contain;
}

.Width-50 {
  width: 25px;
}

.Width-100 {
  width: 50px;
}

.Mt-120 {
  margin-top: 120px;
}

.Align-items-flex-start {
  align-items: flex-start;
}

.Text-align-center {
  text-align: center;
}

.signIn {
  font-family: MyriadPro;
}

.Input-bg.optionText{
  background-image: linear-gradient( #A9703B, #d0935a, #A9703B);
  border: 3px solid #C49A6C;
  font-family: MyriadPro;
  outline: 4px solid #754C29;
  font-family: MyriadPro;
  border-right-width: 4px;
  background: linear-gradient(300.66deg, rgba(255, 255, 255, 0.19) -51.14%, rgba(190, 30, 45, 0.19) -3.21%, rgba(255, 255, 255, 0.19) 39.31%, rgba(190, 30, 45, 0.19) 87.23%);
  background-blend-mode: luminosity;
  box-shadow: inset 0px 0px 6px 6px rgb(117 76 41 / 50%);
}

.Input-bg.characterName{
  padding-right: 3em;
}

.optionText{
  background-image: linear-gradient( #A9703B, #d0935a, #A9703B);
  border: 3px solid #C49A6C;
  outline: 4px solid #754C29;
  font-family: MyriadPro;
}

.optionSelection{
  color:#66472D;
  letter-spacing: 0.07em;
}

.labelText{
  color:white;
  font-style: normal;
  font-size: 1.5em;
  letter-spacing: 0.1em;
}

.labelTextInnerText{
  background-color: #EFE3DA;
  font-size: 1.5em;
}

/*.helloText{*/
/*  font-family: DevinneSwash;*/
/*  -webkit-text-fill-color: #FFE8C7; !* Will override color (regardless of order) *!*/
/*  -webkit-text-stroke-width: 2.5px;*/
/*}*/

.mr-1{
  margin-right: 0.25rem
}

.mr-2{
  margin-right: 0.5rem
}

.mr-3{
  margin-right: 0.75rem
}

.mr-4{
  margin-right: 1rem
}

.mr-5{
  margin-right: 1.25rem
}

.pr-1{
  padding-right: 0.25rem
}

.pr-2{
  padding-right: 0.5rem
}

.pr-3{
  padding-right: 0.75rem
}

.pr-4{
  padding-right: 1rem
}

.pr-5{
  padding-right: 1.25rem
}

.ml-1{
  margin-left: 0.25rem
}

.ml-2{
  margin-left: 0.5rem
}

.ml-3{
  margin-left: 0.75rem
}

.ml-4{
  margin-left: 1rem
}

.ml-5{
  margin-left: 1.25rem
}

.pl-1{
  padding-left: 0.25rem
}

.pl-2{
  padding-left: 0.5rem
}

.pl-3{
  padding-left: 0.75rem
}

.pl-4{
  padding-left: 1rem
}

.pl-5{
  padding-left: 1.25rem
}

.roomCard{
  width: fit-content;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.yesButton{
  background-image: url("../public/box-btn.png");
  background-size: 100% 100%;
  font-family: MyriadPro;
}

.noButton{
  background-image: url("../public/box-btn2.png");
  background-size: 100% 100%;
  font-family: MyriadPro;
}

.buttons{
  font-family: MyriadPro;
}

.genderOption{
  font-family: MyriadPro;
  font-size: 1.5em;
  color: white;
  width: fit-content;
}

.paginationCustom{
  color:white;
  background-color: #66472D;
}

.customPageLink{
  position: relative;
  display: block;
  color: white;
  text-decoration: none;
  border: 1px solid #dee2e6;
  background: linear-gradient(275.26deg, rgba(190, 30, 45, 0.19) 10.67%, rgba(255, 255, 255, 0.19) 26.8%, rgba(190, 30, 45, 0.19) 42.09%, rgba(255, 255, 255, 0.19) 57.18%, rgba(190, 30, 45, 0.19) 74.49%, rgba(255, 255, 255, 0.19) 97.27%);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 0.375rem 0.75rem;
}


.optionText.active .customPageLink {
  z-index: 3;
  color: #897f77;
  background-color: #EFE3DA;
}

a:hover.customPageLink {
  color:white
}
